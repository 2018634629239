const newsletter = require('newsletter/client');

function init() {
  newsletter.initNewsletterForm();

  initTabs();
}

function initTabs() {

  document.addEventListener('click', (e) => {
    if (e.target.closest('.frontpage-content')) {
      let button = e.target.closest('.tabs__menu-button');
      if (!button) return;

      e.preventDefault();

      let i, tablinks;

      tablinks = document.getElementsByClassName('tabs__menu-button');

      /*
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].classList.remove('active');
      }

      button.classList.add('active');*/

      document.querySelector(button.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    }
  });

  function changeTabState(sections, tabs, tabsHeight) {
    let index = sections.length;

    while(--index && window.scrollY + tabsHeight < sections[index].offsetTop) {}

    tabs.forEach((tab) => tab.classList.remove('active'));
    tabs[index].classList.add('active');
  }

  document.addEventListener('DOMContentLoaded', () => {
    const sections = document.querySelectorAll(".tabs__content");
    const stickyTabs = document.querySelector('.tabs__menu');
    const stickyTabsEls = stickyTabs.querySelectorAll('.tabs__menu-button');

    const observer = new IntersectionObserver(
      (entries) => {
        entries[0].target.classList.toggle("sticky", !entries[0].isIntersecting);
      }, {rootMargin: `-${stickyTabs.offsetHeight + 1}px`, threshold: 0});

    observer.observe(stickyTabs);

    changeTabState(sections, stickyTabsEls, stickyTabs.offsetHeight);
    window.addEventListener('scroll', () => changeTabState(sections, stickyTabsEls, stickyTabs.offsetHeight));

    // window.addEventListener('scroll', () => {
    //   let current = "tab-1";
    //   console.log('current', current)

    //   sections.forEach((section) => {
    //     const sectionTop = section.offsetTop;
    //     console.log('sectionTop', sectionTop)
    //     console.log('window.scrollTop', window.pageYOffset | document.body.scrollTop)
    //     if (window.pageYOffset | document.body.scrollTop >= sectionTop - 60) {
    //       current = section.getAttribute('id');
    //       console.log('change current', current)
    //     }
    //   });

    //   stickyTabs.querySelectorAll('.tabs__menu-button').forEach((tab) => {
    //     tab.classList.remove('active');
    //     if (tab.classList.contains(current)) {
    //       tab.classList.add('active');
    //     }
    //   });
    // });
  });
}

init();
