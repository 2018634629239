const t = require('engine/i18n/t');
const pluralize = require('engine/text-utils/pluralize');

class multiSelect {
  constructor(options) {
    this.elem = options.elem;
    this.select = this.elem.querySelector('select');
    this.textContainer = this.elem.querySelector('.multiselect__active-button');
    this.options = [...this.select.querySelectorAll('option')];
    this.defaultValue = this.textContainer.textContent;
    this.status = 'closed';
    this.elem.querySelector('.multiselect__container').insertAdjacentHTML('beforeend', this.createDropdown());
    this.setButtonTitle();
    this.bindHandlers();
  }

  createDropdown() {
    return `
      <div class='multiselect__dropdown-container'>
        <div class='multiselect__dropdown'>
          ${ this.options.map(option => {
              return `<div class='multiselect__item${option.selected ? ' multiselect__item_checked' : ''}' data-value='${option.value}'>
                <span class='multiselect__item-title'>${option.textContent + (option.value === 'advanced' ? `<span class='multiselect__greyed-text'>${t('site.subscribe.common_updates_text')}</span>` : ``)}</span>
              </div>`;
            }).join(``)
          }
        </div>
      </div>
    `;
  }

  bindHandlers() {
    this.textContainer.addEventListener('click', this.toggleDropdown.bind(this));
    for (let item of this.elem.querySelectorAll('.multiselect__item')) {
      item.addEventListener('click', this.onChange.bind(this));
    }
    this.select.addEventListener('change', this.setButtonTitle.bind(this));
  }

  toggleDropdown(e) {
    e.stopPropagation();
    this.elem.classList.toggle('multiselect_opened');
    this.toggleStatus();
    if (this.status === 'opened') {
      this.boundCloseDropdown = this.closeDropdown.bind(this);
      document.addEventListener('click', this.boundCloseDropdown);
    }
  }

  toggleStatus() {
    if (this.status === 'closed') this.status = 'opened';
    else this.status = 'closed';
  }

  closeDropdown(e) {
    if (e.target.closest('.multiselect__dropdown-container')) return;
    this.status = 'closed';
    this.elem.classList.remove('multiselect_opened');
    document.removeEventListener('click', this.boundCloseDropdown);
  }

  onChange(e) {
    const item = e.target.closest('.multiselect__item');
    item.classList.toggle('multiselect__item_checked');

    const targetOption = this.options.filter(option => option.value === item.getAttribute('data-value')).pop();
    targetOption.selected = item.classList.contains('multiselect__item_checked');

    this.select.dispatchEvent(new Event('change'));
  }

  setButtonTitle() {
    const values = this.getValues();

    if (values.length === 1 && values.includes('advanced')) {
      this.textContainer.textContent = this.defaultValue;
    } else if (values.length) {
      this.textContainer.textContent = values.length + ' ' + pluralize(values.length, t('site.subscribe.newsletters'));
    } else {
      this.textContainer.textContent = t('site.subscribe.no_selected');
    }
  }

  getValues() {
    return this.options.filter(option => option.selected).map(option => option.value);
  }
}

module.exports = multiSelect;
