const Spinner = require('client/spinner');
const xhr = require('client/xhr');
const notification = require('client/notification');
const MultiSelect = require('./multiSelect');
const { Recaptcha } = require('client/recaptcha');
const t = require('engine/i18n/t');

const LANG = require('config').lang;

t.i18n.add('newsletter.client', require('../locales/client/' + LANG + '.yml'));

function submitSubscribeForm(form, onSuccess) {

  if (!form.elements.email.value) {
    return;
  }

  const slugElem = form.elements.slug;
  let slugs = [...slugElem.querySelectorAll('option:checked')].map(option => option.value);
  if (!slugs.length) slugs = slugElem.value;

  let userLogged;
  if (form.elements['subscribe-email']) userLogged = true;

  if (!userLogged && !slugs.length) {
    new notification.Error(t("newsletter.client.choose_newsletter"));
    return;
  }


  const body = {
    email: form.elements.email.value,
    slug: slugs
    // no remove/replace => action is "add"
  };

  if (form.elements['g-recaptcha-response']) {
    body['g-recaptcha-response'] = form.elements['g-recaptcha-response'].value;
  }

  // if the user is logged in we let him manage his subscriptions, not only add

  if (userLogged) body.replace = true;

  const request = xhr({
    method: 'POST',
    url:    form.action,
    body:   body
  });

  const submitButton = form.querySelector('[type="submit"]');

  const spinner = new Spinner({
    elem:      submitButton,
    size:      'small',
    elemClass: 'button_loading'
  });
  spinner.start();
  submitButton.disabled = true;

  request.addEventListener('loadend', ()=> {
    spinner.stop();
    submitButton.disabled = false;
  });

  request.addEventListener('success', function(event) {
    if (this.status == 200) {
      new notification.Success(event.result.message, 'slow');

      let gaEvent = form.elements.gaEvent && JSON.parse(form.elements.gaEvent.value);

      if (gaEvent) {
        // GA4
        // window.ga('send', 'event', gaEvent);
      }

      onSuccess && onSuccess();
    } else {

      new notification.Error(event.result.message);
    }
  });

}


function initNewsletterForm() {
  let forms = document.querySelectorAll('[data-newsletter-subscribe-form]');

  for(let form of forms) {
    const isLogged = form.elements.email.type === 'hidden';
    const select = form.querySelector('.multiselect');

    if (select) {
      const multiSelect = new MultiSelect({ elem: select});
      const formButton = form.querySelector('button[type="submit"]');
      const formButtonTextEl = formButton.querySelector('span');

      if (form.elements.slug) form.elements.slug.addEventListener('change', () => {
        formButton.disabled = !multiSelect.getValues().length && !isLogged;
        if (!multiSelect.getValues().length && isLogged) {
          formButtonTextEl.textContent = t('site.subscribe.button_unsubscribe');
        } else {
          formButtonTextEl.textContent = t('site.subscribe.button');
        }
      });
    }

    let recaptcha = new Recaptcha(form);

    form.onsubmit = async function(event) {
      event.preventDefault();

      await recaptcha.validateForm(form);

      submitSubscribeForm(form);
    };
  }
}

exports.initNewsletterForm = initNewsletterForm;
exports.submitSubscribeForm = submitSubscribeForm;
